import React from "react";
import { useSelector } from "react-redux";
import OfferItem from "./Item";
import "./offers.scss";
import { useParams } from "react-router-dom";

const Offers = (props) => {
  const params = useParams();
  let isLoading = !params.id ? false : true,

    queryId = params ? params.id : false,
    deepLinkOffer = params.id ? findOffer(params.id) : "";
  let available = useSelector((state) => state.offer.collections.available);

  function findOffer (id) {
    let deepLinkOffer = available.find(
      (cpn) => cpn.mdid === parseInt(id)
    );
    isLoading = false;
    return deepLinkOffer;
  }

  function RenderDeepLink (props) {
    const { cpn } = props;
    return (
      <div>
        <div className="SingleOffer">
          <OfferItem coupon={cpn} key={cpn.mdid} />
        </div>
      </div>
    );
  }

  function RenderOfferList (props) {
    return (
      <div className="OfferList">
        {props.available.map((cpn) => {
          return <OfferItem coupon={cpn} key={cpn.mdid} />;
        })}
      </div>
    );
  }



  if (isLoading) {
    return "";
  }
  if (queryId && deepLinkOffer) {
    return <RenderDeepLink cpn={deepLinkOffer} />;
  } else if (!queryId && available.length > 0) {
    return (
      <div>

        <RenderOfferList available={available} />
      </div>
    );
  } else {
    return "There are no available offers";
  }
};

export default Offers;
