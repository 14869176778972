import React, { Component } from "react";
import "font-awesome/css/font-awesome.css";

class FontAwesome extends Component {
  render() {
    return (
      <i className={"FontAwesome fa fa-" + this.props.type} aria-hidden="true">
        {this.props.content}
      </i>
    );
  }
}

export default FontAwesome;
